<template>
  <div class="flex flex-col gap-y-2">
    <div class="flex justify-between items-center">
      <label
        :for="name"
        class="text-button-small text-white opacity-[0.87] ltr:tracking-[0.6px] rtl:tracking-normal"
      >
        {{ label }}
      </label>
      <slot name="labelEnd"></slot>
    </div>
    <div
      :class="[
        { '!border-brand-red-error': meta.touched && !meta.valid },
        { 'bg-white/[0.15]': inputValue?.trim().length > 0 },
        focused ?'focus:border-white/80':'border-transparent',
        disabled ? 'bg-white/30' : 'hover:bg-white/[0.15]',
        {'plain-text':isRTL}
      ]"
      class="rounded bg-white/10 flex items-center border box-border"
      @click.stop="$emit('onClick')"
    >
      <input
        :name="name"
        :placeholder="placeholder"
        :value="inputValue"
        :type="inputType"
        :disabled="disabled"
        :readonly="readonly"
        autocomplete="off"
        class="w-full py-[12px] ps-6 bg-transparent text-body-big text-white opacity-[0.87] outline-none placeholder-white disabled:text-white disabled:opacity-50"
        @input="handleInputChange"
        @blur="onBlur"
        @focus="focused=true"
      />
      <button
        v-if="inputValue?.trim().length > 0 && !readonly && !disabled"
        tabindex="-1"
        class="w-12 h-full flex justify-center items-center"
        @click.stop="handleResetFeild"
        type="button"
      >
        <Icon name="IconTimesWhite" size="24"></Icon>
      </button>
    </div>

    <span v-if="showErrorMessage && meta.touched && !meta.valid" class="text-red-700">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script setup>
import { useField } from "vee-validate";
const { isRTL } = useCurrentLocale()
const props = defineProps({
  name: String,
  label: String, // should be i18nized
  placeholder: String, // should be i18nized
  inputType: {
    type: String,
    default: () => "text",
  },
  disabled: {
    type: Boolean,
    default: () => false,
  },
  readonly: {
    type: Boolean,
    default: () => false,
  },
  maxLength: {
    type: Number
  },
  showErrorMessage: {
    type: Boolean,
    default: () => true,
  },
  initialValue: String,
});

const name = toRef(props, "name");
const focused = ref(false);
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
  resetField,
} = useField(name, undefined, { initialValue: props.initialValue });

const handleResetFeild = (e) => {
  resetField({ value:null })
}

const maxLength = val => {
  return val && props.maxLength ? String(val).slice(0,props.maxLength) : val;
}

//Processing input box data
const inputValueFilter = (funcs,targetVal) => {
  return Array.isArray(funcs) ? funcs.reduce( (prev,current) => {
    return current(prev(targetVal))
  },(val) =>val) : targetVal
}

const handleInputChange = e => {
  e.target.value = inputValueFilter([maxLength],e.target.value)
  handleChange(e);
}

const onBlur = (e) => {
  focused.value = false;
  handleBlur(e)
}

</script>

<style lang="scss" scoped></style>
