import { defineRule,configure } from "vee-validate";
// import { required, max } from "@vee-validate/rules";

export default function () {
  const { t } = useI18n();
  configure({ validateOnInput:true });
  // defineRule("required", required);
  // defineRule("maxLength", max);

  defineRule("email", (value, [target], ctx) => {
    if (!value) return true;

    if (!/^[A-Z0-9.+-_~]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(value)) {
      return t("Please enter a valid email address");
    }

    return true;
  });

  defineRule("required", (value) => {
    if (!value || !value.length || !value.trim()) {
      return t("This field is required");
    }
    return true;
  });

  defineRule("maxLength", (value) => {
    //  write rule here
    if (value.length > 50) {
      return t("This field cannot exceed 50 characters.");
    }
    return true;
  });

  defineRule("minLength", (value, [limit]) => {
    // The field is empty so it should pass
    if (!value || !value.length) {
      return true;
    }
    if (value.length < limit) {
      return t(`This field must be at least ${limit} characters`);
    }
    return true;
  });

  defineRule("hasUppercase", (value, [count]) => {
    const regex = /[A-Z]/;
    return regex.test(value);
  });

  defineRule("hasLowercase", (value, [limit]) => {
    const regex = /[a-z]/;
    return regex.test(value);
  });

  defineRule("hasNumber", (value, [limit]) => {
    const regex = /[0-9]/;
    return regex.test(value);
  });

  defineRule("hasSymbol", (value, [limit]) => {
    const regex = /[^A-Za-z0-9\s]/;
    return regex.test(value);
  });

  defineRule("equalTo", (value, [target], ctx) => {
    if (ctx.form[target] == value) return true;

    return t("Please make sure the passwords match!");
  });

  defineRule("isTrue", (value) => {
    return value;
  });
}
